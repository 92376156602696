import React from "react"
import { Link } from "react-router-dom"
import { Button, Card, Flex, Sizes } from "@aws-amplify/ui-react"
import { FaPencil, FaPenToSquare, FaRegEye, FaRegStar, FaStar } from "react-icons/fa6"

export const imageHash = (hashSeed: number, uuid: string, N: number = 0): number => {
  let x = hashSeed
  uuid.split("-").forEach(segment => x ^= Number.parseInt(segment, 16))
  x = Math.abs(x)
  return N
    ? x % N
    : x
}

export enum StarStates {
  hidden = "hidden",
  empty = "empty",
  filled = "filled"
}
interface ListItemProps {
  className?: string
  title: string | React.ReactNode
  href: string
  imageSrc: string
  details: string | React.ReactNode
  size?: Sizes
  starState?: StarStates
  hasViewButton?: boolean
  hasEditButton?: boolean
  viewButtonIcon?: React.ReactNode
}
export const ListItem = ({ href, title, imageSrc, className, details, size = "large", starState = StarStates.hidden, hasViewButton = true, hasEditButton = false, viewButtonIcon = <FaRegEye /> }: ListItemProps) => {
  const buttonFlexClasses = size === "large"
    ? "!flex-col"
    : ""
  return <Card className={`rm-list-item ${className} ${size.toString()}`}>
    <Flex className="justify-start items-center gap-4 inline-flex">
      <div className="img" style={{
        background: `transparent url('${imageSrc}') 0% 0% no-repeat padding-box`
      }} />
      <div className="info">
        <Link className="title" to={href}>{title} {
          (starState === StarStates.hidden)
            ? '' :
            (starState === StarStates.empty)
              ? <FaRegStar className="fav" />
              : <FaStar className="fav is-fav" />
        }</Link>
        <div className="details">
          {details}
        </div>
      </div>
    </Flex>

    {(hasViewButton || hasEditButton) &&
      <Flex className={buttonFlexClasses}>
        {hasViewButton && <Link to={href}>
          <Button className="button-view has-icon"><span role="img" aria-label="Ansehen">{viewButtonIcon}</span></Button>
        </Link>
        }
        {/* todo: css class button-view is incorrect ; button-edit has wrong colors */}
        {hasEditButton && <Link to={`${href}/edit`}>
          <Button className="button-view has-icon"><span role="img" aria-label="Bearbeiten"><FaPenToSquare /></span></Button>
        </Link>
        }
      </Flex>
    }
  </Card>
}
