import { useContext, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { Button, Card, Collection, Flex, Label, SwitchField } from "@aws-amplify/ui-react"
import { del, post, put } from "aws-amplify/api"
import { Cache } from 'aws-amplify/utils'
// import { FaRegTrashCan } from "react-icons/fa6"

import { FeedComponentCreateDto, FeedComponentCreateSchema, FeedComponentDto, FeedComponentUpdateDto, FeedComponentUpdateSchema, Organization } from "sds"
import { ResponseMessage } from "../../../../../../@types/sds"

import { CURRENCY_SYMBOL, UNIT_OF_MASS } from "../../../org-settings"
import { currentSession } from "../../../Utils.AmplifyAuth"
import { AppContext, AppContextHelper } from "../../../AppContext"
import { getLinkToOrgFeedComponents } from "../../../LinkUtils"
import Modal from "../../../components/Modal"
import { ensureImagePath, getImagePath } from "../-components/FeedComponentUtils"

import '../RecipeManagement.css'

type ComponentModes = "create" | "edit" | "invalid"

const getEmptyFeedComponent = (org: Organization): FeedComponentDto => {
  return {
    group: undefined,
    id: '',
    image: undefined,
    include_in_stats: true,
    name: '',
    org_id: org.id,
    price_per_kg: undefined,
    ts_gehalt_percent: undefined,
    created_at: '',
    created_by: '',
    updated_at: '',
    updated_by: '',
  }
}

export default function FeedComponentCreateEdit() {
  const params = useParams()
  const navigate = useNavigate()
  const appCtx = useContext(AppContext)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const isCreateMode = window.location.pathname.endsWith('/new')
  const isEditMode = window.location.pathname.endsWith('/edit')
    && params.orgId
    && params.feedComponentId
  const componentMode: ComponentModes = isCreateMode
    ? "create"
    : isEditMode
      ? "edit"
      : "invalid"
  const modalCallback = async (value: string) => {
    console.log("> modalCallback:", value)
    if (value.trim().toLowerCase() === 'ja') {
      console.log("  wanna delete: " + feedComponent.id)
      await deleteFeedComponent(feedComponent.id)
      /** @todo set global success message with data from `feedComponent` */

      console.log("  Invalidating Cache")
      const cacheKey = "frontendApi#/users/current"
      await Cache.removeItem(cacheKey)

      if (appCtx.reloadUserData) {
        console.log("  Reloading data")
        await appCtx.reloadUserData()
      } else {
        console.warn("  Not reloading data; callback not set")
      }

      console.log("  Navigating back")
      navigate(getLinkToOrgFeedComponents(org))
    } else {
      console.log("  NO wanna delete")
    }
    setShowDeleteModal(false)
  }

  const org = AppContextHelper.findOrg(params.orgId!, appCtx)
  const feedComponent: FeedComponentDto = isEditMode
    ? AppContextHelper.findFeedComponent(org, params.feedComponentId!)
    : getEmptyFeedComponent(org)

  const methods = useForm<FeedComponentCreateDto>({
    values: feedComponent,
    mode: "onBlur"
  })
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors }
  } = methods

  const [selectedComponentImageId, setSelectedComponentImageId] = useState<string>(feedComponent.image || "")
  const [bgImageSrc, setBgImageSrc] = useState<string>("")

  if (componentMode === "invalid") {
    return <div>Errrr... invalid component mode</div>
  }

  const onComponentImageClick = (selectedId: string) => {
    console.log(`> onComponentImageClick(${selectedId})`)
    // Update image selector component
    setSelectedComponentImageId(selectedId)
    // Update form data
    setValue("image", selectedId)
    // Update background image
    setBgImageSrc(getImagePath(selectedId))
  }
  ensureImagePath(feedComponent, bgImageSrc, setBgImageSrc)

  const onSubmit: SubmitHandler<FeedComponentCreateDto> = async (data) => {
    console.log("▶️ onSubmit")
    /** @todo Implement proper client-side validation (different for 'create' and 'update') */
    const isOrigValid = await FeedComponentCreateSchema.isValid(data)
    if (!isOrigValid) {
      console.warn("❌ Original data is not valid")
      console.log('data', data)
      try {
        const validated: FeedComponentCreateDto = await FeedComponentCreateSchema.validate(data, { abortEarly: false })
      } catch (err) {
        if (err instanceof Object
          && err.hasOwnProperty("errors")) {
          /** @ts-ignore */
          console.log("Validation errors (original)", err.errors)
        }
      }
    }
    // else {
    //   console.log("✅ Original data is valid")
    // }
    const cleaned = cleanData(data, isCreateMode)
    // const clean = data
    // console.log('clean', clean)
    // console.log("\n"
    //   + `${clean.name}\n`
    //   + `  Komponenten\n`
    //   + clean.RecipeComponents.map(rco => {
    //     const fcId = rco.feed_component_id
    //     const fc = AppContextHelper.findFeedComponent(org, fcId)
    //     return `    ${fc.name}\t${rco.mass_kg} kg\t${rco.mixing_time_s} s`
    //   }).join("\n")
    //   + "\n"
    //   + `  Ausladegruppen\n`
    //   + clean.RecipesAusladegruppen.map(rag => {
    //     const agId = rag.ausladegruppe_id
    //     const ag = AppContextHelper.findAusladegruppe(org, agId)
    //     return `    ${ag.name}\t${ag.number_of_animals} T`
    //   }).join("\n")
    // )
    /** @todo Implement proper client-side validation (different for 'create' and 'update') */
    if (!FeedComponentCreateSchema.isValidSync(cleaned)) {
      console.warn("❌ Cleaned data is not valid")
      try {
        const validated: FeedComponentCreateDto = await FeedComponentCreateSchema.validate(cleaned, { abortEarly: false })
      } catch (err) {
        /** @ts-ignore */
        console.log("Validation errors (cleaned)", err.errors)
        const response: ResponseMessage = {
          res: {
            code: "400",
            /** @ts-ignore */
            message: `${err.errors.length} Errors. The first one is: ${err.errors[0]}`
          },
          sessionId: ""
        }
        return response
      }
    }
    else {
      console.log("✅ Cleaned data is valid")
    }
    const result = isEditMode
      ? await updateFeedComponent(feedComponent.id, cleaned as FeedComponentUpdateDto)
      : await createFeedComponent(cleaned as FeedComponentCreateDto)
    /** @todo Result is meaningless? */
    /** @todo Error handling */

    console.log("Invalidating Cache")
    const cacheKey = "frontendApi#/users/current"
    await Cache.removeItem(cacheKey)

    if (appCtx.reloadUserData) {
      console.log("  Reloading data")
      await appCtx.reloadUserData()
    } else {
      console.warn("  Not reloading data; callback not set")
    }

    console.log("Navigating back")
    navigate(getLinkToOrgFeedComponents(org))

    return result
  }

  /** Cleans the form data before submission. */
  function cleanData(data: FeedComponentCreateDto, isCreateMode: boolean): FeedComponentCreateDto | FeedComponentUpdateDto {
    console.debug("▶️ cleanData")
    /** @ts-ignore - user input can be string */
    if ("" === data.ts_gehalt_percent) {
      delete data.ts_gehalt_percent
    }
    /** @ts-ignore - user input can be string */
    if ("" === data.price_per_kg) {
      delete data.price_per_kg
    }
    let cleaned
    if (isCreateMode) {
      cleaned = FeedComponentCreateSchema.cast(data)
    } else {
      cleaned = FeedComponentUpdateSchema.cast(data)
    }
    return cleaned
  }
  const prepareApiCall = async (path: string, body: any = false) => {
    console.debug("▶️ prepareApiCall")
    const tokens = await currentSession()
    const apiName = 'frontendApi'
    const options = {
      headers: {
        Authorization: `Bearer ${tokens?.idToken}`
      },
      body: body
    }
    if (!body) {
      delete options.body
    }
    console.info(`Prepared API call to ${path}`)
    return { apiName, path, options }
  }
  async function createFeedComponent(data: FeedComponentCreateDto) {
    console.log("▶️ createFeedComponent")
    const apiCallParams = await prepareApiCall(`/feed-components`, data)
    const restOperation = post(apiCallParams)
    const apiResponse = await (await restOperation.response).body.json() as unknown as ResponseMessage
    console.log("Got API response:", apiResponse)
    console.log("Got restOperation.response:", restOperation.response)
    return apiResponse
  }
  async function updateFeedComponent(feedComponentId: string, data: FeedComponentUpdateDto): Promise<ResponseMessage> {
    // -> PUT
    console.log("▶️ updateFeedComponent")
    const apiCallParams = await prepareApiCall(`/feed-components/${feedComponentId}`, data)
    const restOperation = put(apiCallParams)
    const apiResponse = await (await restOperation.response).body.json() as unknown as ResponseMessage
    console.log("Got API response:", apiResponse)
    console.log("Got restOperation.response:", restOperation.response)
    return apiResponse
  }
  async function deleteFeedComponent(feedComponentId: string) {
    console.log("▶️ deleteFeedComponent", feedComponentId)
    const apiCallParams = await prepareApiCall(`/feed-components/${feedComponentId}`)
    const restOperation = del(apiCallParams)
    const statusCode: number = (await restOperation.response).statusCode
    console.log("Got API response with statusCode:", statusCode)
    console.log("Got restOperation.response:", restOperation.response)
    return statusCode
  }

  const componentImages = [
    // Maissilage
    {
      "title": "Maissilage",
      "href": "corn-264520_560.jpg",
    },
    {
      "title": "Maissilage",
      "href": "corn-5199393_1920.jpg",
    },
    {
      "title": "Grassilage",
      "href": "unknown.png",
    },
    // Ballensilage
    {
      "title": "Ballensilage",
      "href": "hay-bales-167539_1920.jpg",
    },
    // Stroh
    {
      "title": "Stroh",
      "href": "straw-1649857_1920.jpg",
    },
    // "straw-1649857_560.jpg"
    // Heu
    {
      "title": "Heu",
      "href": "hay-167567_1920.jpg",
    },
    {
      "title": "Heu",
      "href": "hay-337915_1920.jpg",
    },
    // "hay-8074152_1920.jpg",
    // Wasser
    {
      "title": "Wasser",
      "href": "drop-545377_1165.jpg",
    },
    {
      "title": "Wasser",
      "href": "drop-of-water-578897_1280.jpg",
    },
    {
      "title": "Wasser",
      "href": "water-8228076_1920.jpg",
    },
    // Rapsschrot
    // "rapeseed-3359316_1920.orig.jpg",
    {
      "title": "Rapsschrot",
      "href": "rapeseed-3359316_280.jpg",
    },
    // "rapeseed-4269494_1920.jpg", "rapeseed-5120108_1920.jpg",
    {
      "title": "Sojaschrot",
      "href": "unknown.png",
    },
    // Kraftfutter
    {
      "title": "Kraftfutter",
      "href": "feed-2615928_1920.jpg",
    },
    {
      "title": "CCM",
      "href": "unknown.png",
    },
    {
      "title": "Biertreber",
      "href": "unknown.png",
    },
    // Rübenschnitzel
    // "culture-111530_1920.jpg", "sugar-beet-3824010_1920.jpg",
    {
      "title": "Rübenschnitzel",
      "href": "sugar-beet-3824010_560.jpg",
    },
    {
      "title": "Melasse",
      "href": "unknown.png",
    },
    {
      "title": "Mineralfutter",
      "href": "unknown.png",
    },
    {
      "title": "Sonstiges",
      "href": "unknown.png",
    },
  ]

  return <>
    <div className="rm-edit-page">
      <div className="hdr-img" style={{
        background: `transparent url('${bgImageSrc}') 0% 0% no-repeat padding-box`
      }} />
      <div className="content-overlay">
        <div className="header">
          <div className="info">
            <h2>{feedComponent.name}</h2>
          </div>
        </div>

        <div className="details">
          {/* pass all methods into the context */}
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>

              <Flex direction="column" gap="small">
                <Flex direction="row" gap="small">
                  <Label htmlFor="name">Komponentenname:</Label>
                  <input
                    {...register("name", { required: "Komponentenname angeben" })}
                    placeholder={feedComponent.name || 'Neue Komponente'}
                    className={errors?.name ? "error" : ""}
                  />
                  {errors?.name && <div>Fehler: {errors?.name.message}</div>}
                </Flex>

                <Flex direction="row" gap="small">
                  <Label htmlFor="ts_gehalt_percent">TS-Gehalt:</Label>
                  <input {...register("ts_gehalt_percent")} /> %
                </Flex>
                <Flex direction="row" gap="small">
                  <Label htmlFor="price_per_kg">Preis:</Label>
                  <input {...register("price_per_kg")} /> {CURRENCY_SYMBOL} / {UNIT_OF_MASS}
                </Flex>
                {/* <Flex direction="row" gap="small">
                  <Label htmlFor="group">Futtermittel-Gruppe:</Label>
                  <input {...register("group")} /> {/ ** @todo Drop-down? * /}
                </Flex> */}
                <Flex direction="row" gap="small">
                  <SwitchField
                    id="include_in_stats"
                    name="include_in_stats"
                    label="In Statistiken berücksichtigen:"
                    defaultChecked={true}
                    checked={watch("include_in_stats")}
                    onChange={({ target }) => {
                      setValue("include_in_stats", target.checked)
                    }}
                  />
                </Flex>

                <div>
                  <div className="mb-2">Bild auswählen:</div>
                  <input {...register("image")} type="hidden" />
                  <Collection type="list" direction="row" wrap="wrap"
                    items={componentImages}>
                    {(item, index) => {
                      const imageId = item.title + "#" + item.href
                      const isActive = imageId === selectedComponentImageId
                      return <Card key={imageId} className="rm-list-item small !border-none">
                        <Flex direction="column" onClick={() => onComponentImageClick(imageId)}>
                          <div className={"img" + (isActive
                            ? " border-[#25C401] border-[3px]"
                            : "")} style={{
                              background: `transparent url('/images/fc/${item.href}') 0% 0% no-repeat border-box`,
                              // padding: "5px"
                            }} />
                          <div className={"p-1" + (isActive
                            ? " border-[#25C401] border-b-2"
                            : "")}>{item.title}</div>
                        </Flex>
                      </Card>
                    }}
                  </Collection>
                </div>

                <div className="buttons">
                  <Button variation="primary" type="submit">
                    Speichern
                  </Button>
                  <Link to="..">
                    <Button>Abbrechen</Button>
                  </Link>
                  {componentMode === "edit"
                    && <Button variation="warning" onClick={() => { setShowDeleteModal(true) }}>Löschen</Button>}
                  {componentMode === "edit"
                    && showDeleteModal
                    &&
                    <Modal buttons={[
                      {
                        text: 'Ja',
                        variation: "destructive"
                      },
                      { text: 'Nein' }
                    ]} show={showDeleteModal} callback={modalCallback}>
                      <div className="title">
                        Komponente wirklich löschen?
                      </div>
                      <div>
                        {`Wollen Sie die Komponente "${feedComponent.name}" wirklich löschen?`}
                      </div>
                    </Modal>}
                </div>
              </Flex>
            </form>
          </FormProvider>

          {componentMode === "edit"
            &&
            <div className="mt-5 text-xs">
              <div>Zuletzt bearbeitet am {feedComponent.updated_at} von {feedComponent.updated_by}</div>
              <div>Angelegt am {feedComponent.created_at} von {feedComponent.created_by}</div>
            </div>
          }
        </div>
      </div>
    </div>

    {/* <small>* Die Icons "xmark (light)", "pen-to-square (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.</small> */}
  </>
}
