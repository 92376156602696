import { FeedComponentDto } from "sds"
import { imageHash } from "./ListItem"

const FC_NUM_IMAGES = 4
const FC_IMAGE_HASH_SEED = 271

/**
 * Takes a filename, or an ImageId of the form `${Title}#${Filename}`, and returns the path to the image.
 * @param filenameOrImageId 
 */
export const getImagePath = (filenameOrImageId: string) => {
  let imageFilename = filenameOrImageId.includes("#")
    ? filenameOrImageId.split("#")[1]
    : filenameOrImageId
  return `/images/fc/${imageFilename}`
}

/**
 * Takes a filename, or an ImageId of the form `${Title}#${Filename}`, and returns the path to the image.
 * @param filenameOrImageId 
 */
const getFallbackImagePath = (id: string) => {
  /** @todo background: create fallback */
  /** @todo background: Handle "unknown.png" */
  const imageIdx = imageHash(FC_IMAGE_HASH_SEED, id, FC_NUM_IMAGES)
  return `/unlicensed/futter-${imageIdx}.jpg`
}

export const ensureImagePath = (feedComponent: FeedComponentDto, imageSrc: string, setImageSrc: React.Dispatch<React.SetStateAction<string>>) => {
  if (!imageSrc) {
    if (feedComponent.image) {
      console.debug("Using image from feedcomponent")
      setImageSrc(getImagePath(feedComponent.image))
    } else {
      console.log("❌ Using some form of image fallback")
      setImageSrc(getFallbackImagePath(feedComponent.id))
    }
  }
}