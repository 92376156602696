import { Card, Flex } from "@aws-amplify/ui-react"
import { FaChartSimple } from "react-icons/fa6"
import { ChartData, ChartDataset } from "chart.js/auto"
import { Chart as ChartJS } from "chart.js"
import 'chartjs-adapter-luxon'
import { Line } from "react-chartjs-2"
import annotationPlugin from 'chartjs-plugin-annotation';

import { chartData } from "./chartData" // Importing the static chart data

ChartJS.register(
  annotationPlugin
)

// Farben die man haben möchte
// https://www.heavy.ai/blog/12-color-palettes-for-telling-better-stories-with-your-data
const colorPalette = [
  '#87bc45', '#27aeef', '#ef9b20', '#b33dc6', '#f46a9b'
]

// Farben auswählen (Offset verwenden, wenn man nicht dieselben Farben in beiden Charts will)
function getPersistentColor(index: number, colorPaletteStartingIndex = 0) {
  return colorPalette[(index + colorPaletteStartingIndex) % colorPalette.length]
}

// Timestamp für x-Achse formatieren
function formatDate(dateString: string, showTime: boolean = false) {
  const date = new Date(dateString);
  if (showTime) {
    return date.toISOString().slice(0, 16).replace('T', ' ');
  } else {
    return date.toISOString().slice(0, 10);
  }
}

type PointInTime = { x: string, y: number }

type StatsDummyChartProps = {
  _className: string
  title: string
  datasets: ChartData<'line', PointInTime[], unknown>
  options: any
}
function StatsDummyChart({ _className, title, datasets, options }: StatsDummyChartProps) {
  return <Card className={`rm-list-item ${_className}`}>
    <div className="info w-full">
      <div className="title">{title} {
        // (starState === StarStates.hidden)
        //   ? '' :
        //   (starState === StarStates.empty)
        //     ? <FaRegStar className="fav" />
        //     : <FaStar className="fav is-fav" />
      }</div>
      <div className="details w-full">
        <Line data={datasets} options={options} />
      </div>
    </div>
  </Card>
}

function prepareDatasets(chartData: {
  title: string,
  xData: string[][],
  yData: number[][],
  labels: string[],
  xlabel: string,
  ylabel: string
}) {
  const labels = chartData.labels
  const xData = chartData.xData
  const yData = chartData.yData
  const numSeries = xData.length
  const mergedData: PointInTime[][] = []
  for (var s = 0; s < numSeries; s++) {
    const seriesX = xData[s]
    const seriesY = yData[s]
    const seriesMergedXY: PointInTime[] = []
    for (var i = 0; i < seriesX.length; i++) {
      seriesMergedXY.push({
        x: seriesX[i],
        y: seriesY[i]
      })
    }
    mergedData.push(seriesMergedXY)
  }

  const datasets: ChartData<'line', PointInTime[]> = {
    datasets: mergedData.map((series, index) => ({
      label: labels[index],
      data: series,
      fill: false,
      borderColor: getPersistentColor(index),
      tension: 0.05
    })) as ChartDataset<'line', PointInTime[]>[]
  }

  return datasets
}

export default function StatsDummy() {
  const datasetsComponents = prepareDatasets(chartData.plots.AccuracyPerComponent)
  const datasetsDrivers = prepareDatasets(chartData.plots.AccuracyPerDriver)
  /** @todo: In the future, get values from org-settings. */
  const greenAnnotationHalfWidth = 3
  const yellowAnnotationHalfWidth = 1.5
  const greenAnnotationMin = 100 - greenAnnotationHalfWidth
  const greenAnnotationMax = 100 + greenAnnotationHalfWidth
  const yellowUpperAnnotationMin = greenAnnotationMax
  const yellowUpperAnnotationMax = yellowUpperAnnotationMin + yellowAnnotationHalfWidth
  const yellowLowerAnnotationMax = greenAnnotationMin
  const yellowLowerAnnotationMin = yellowLowerAnnotationMax - yellowAnnotationHalfWidth
  const greenAnnotationColor = 'rgba(135, 188, 69, 0.25)'
  const yellowAnnotationColor = 'rgba(208, 208, 39, 0.25)'

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
      },
      annotation: {
        annotations: {
          boxYellowUpper: {
            type: 'box',
            yMin: yellowUpperAnnotationMin,
            yMax: yellowUpperAnnotationMax,
            backgroundColor: yellowAnnotationColor,
            borderWidth: 0,
          },
          boxGreen: {
            type: 'box',
            yMin: greenAnnotationMin,
            yMax: greenAnnotationMax,
            backgroundColor: greenAnnotationColor,
            borderWidth: 0,
          },
          boxYellowLower: {
            type: 'box',
            yMin: yellowLowerAnnotationMin,
            yMax: yellowLowerAnnotationMax,
            backgroundColor: yellowAnnotationColor,
            borderWidth: 0,
          },
        }
      }
    },
    scales: {
      x: {
        bounds: 'data' as const,
        type: 'time' as const,
        ticks: {
          source: 'auto' as const,
          /** @note without a callback, the chart collapses every now and then */
          callback: (value: any) => {
            const dt = new Date(value)
            return ('0' + dt.getDate()).slice(-2)
              + '.'
              + ('0' + (dt.getMonth() + 1)).slice(-2)
              + '. '
            // + ('0' + dt.getHours()).slice(-2)
            // + ':'
            // + ('0' + dt.getMinutes()).slice(-2)
          }
        },
      }
    }
  }

  const icon = <FaChartSimple className="w-5 _h-3.5_ relativ text-[#3E9E29]" />
  const title = "Statistiken"
  const _className = "chart-container"
  return <div className="rm-list-page">
    <Flex className="!h-12 !pb-2 !justify-between !items-center">
      <Flex className="!h-6 !justify-start !items-center gap-3">
        {icon}
        <div className="text-xl font-semibold">{title}</div>
      </Flex>

      {/* <Flex className="!justify-center !items-center gap-2.5">
          <Link to='new'>
            <Button className="button-add has-icon"><span role="img" aria-label="add"><FaPlus /></span></Button>
          </Link>
        </Flex> */}
    </Flex>

    <div className="rm-list-container">
      <StatsDummyChart _className={_className} title={chartData.plots.AccuracyPerComponent.title} datasets={datasetsComponents} options={options} />
      <StatsDummyChart _className={_className} title={chartData.plots.AccuracyPerDriver.title} datasets={datasetsDrivers} options={options} />
    </div>
  </div>
}
