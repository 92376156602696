import { Button, Collection, Flex } from "@aws-amplify/ui-react"
import { FaPlus } from "react-icons/fa6"
import { Link } from "react-router-dom"
import { FilterAndSort } from "./FilterAndSort"

interface ListPageProps {
  _className: string
  icon: React.ReactNode
  title: string
  items: any[]
  renderFunc: (item: any, index: number) => React.ReactElement
}
export const ListPage = ({icon, title, items, renderFunc, _className}: ListPageProps) => {
  return <>
    <div className="rm-list-page">
      <Flex className="!h-12 !pb-2 !justify-between !items-center">
        <Flex className="!h-6 !justify-start !items-center gap-3">
          {icon}
          <div className="text-xl font-semibold">{title}</div>
        </Flex>

        <Flex className="!justify-center !items-center gap-2.5">
          <Link to='new'>
            <Button className="button-add has-icon"><span role="img" aria-label="add"><FaPlus /></span></Button>
          </Link>
        </Flex>
      </Flex>

      <div className="rm-list-container">
        <FilterAndSort />

        <Collection
          type="list"
          items={items}
          className={`rm-list ${_className}`}>
          {(item, index) => renderFunc(item, index)}
        </Collection>
      </div>
    </div>
  </>
}
