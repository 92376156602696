import { Amplify } from 'aws-amplify'
import { get } from "aws-amplify/api"
import { config } from '../config'
import { currentSession } from "../Utils.AmplifyAuth"

export const fetchFromApi = async (apiName: string, path: string) => {
  const tokens = await currentSession()
  if (!tokens) {
    throw new Error("Got no tokens, cannot load user data")
  }
  const options = {
    headers: {
      Authorization: `Bearer ${tokens?.idToken}`
      // Authorization: `Bearer ${tokens?.accessToken}` /** @todo: try accessToken instead */
    }
  }
  const restOperation = get({ apiName, path, options })
  const response = await (await restOperation.response).body.json()
  // console.log(response)
  return response
}

export const getCacheKey = (apiName: string, path: string) => `${apiName}#${path}`

export const configureApis = () => {
  // Configure APIs so they are known
  const existingConfig = Amplify.getConfig()
  Amplify.configure({
    ...existingConfig,
    API: {
      ...existingConfig.API,
      REST: {
        ...existingConfig.API?.REST,
        'frontendApi': {
          endpoint: config.frontendApi.URL,
          region: config.frontendApi.REGION,
        },
        'vehiclesApi': {
          // the legacy SDS vehicles api, e.g. vehicles.list
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION,
        }
      }
    }
  })

}