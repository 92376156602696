import { AnimalGroupDto, Organization } from "sds"
import { getLinkToOrgAnimalGroup } from "../../../LinkUtils"
import { imageHash, ListItem, StarStates } from "./ListItem"

const NUM_AG_IMAGES = 3
const IMAGE_HASH_SEED = 271

interface AnimalGroupProps {
  org: Organization
  item: AnimalGroupDto
  extraDetails?: string | React.ReactNode
  hasViewButton?: boolean
  hideFavStar?: boolean
}

/**
 * A ListItem representing an Animal Group.
 * Used in
 *   - /animal-groups/
 *     - animal count
 *   - /recipes/:recipeId
 *     - animal count
 *     - extraDetails: total weight (of recipeId for this group)
 * @returns 
 */
export default function AnimalGroupItem({ org, item, extraDetails, hasViewButton = true, hideFavStar = false }: AnimalGroupProps) {
  const a = item as AnimalGroupDto
  const imageIdx = imageHash(IMAGE_HASH_SEED, a.id, NUM_AG_IMAGES)
  const imageSrc = `/unlicensed/stall-${imageIdx}.jpg`
  const detailsContent = `${a.number_of_animals} Tiere`
  let details
  if (!extraDetails) {
    details = detailsContent
  }
  else {
    details = "string" === typeof (extraDetails)
      ? detailsContent + extraDetails
      : <>
        <div>{detailsContent}</div>
        {extraDetails}
      </>
  }
  return <ListItem
    key={a.id}
    className="animal-group"
    title={a.name}
    href={getLinkToOrgAnimalGroup(org, a.id, false)}
    imageSrc={imageSrc}
    details={details}
    hasViewButton={hasViewButton}
    starState={hideFavStar
      ? StarStates.hidden
      : StarStates.empty} />
}