export const chartData = {
  "plots": {
    "AccuracyPerComponent": {
      "title": "Fütterungsgenauigkeit nach Komponenten",
      "xData": [
        [
          "2024-08-18",
          "2024-08-19",
          "2024-08-20",
          "2024-08-21",
          "2024-08-22",
          "2024-08-23",
          "2024-08-24",
          "2024-08-25",
          "2024-08-26",
          "2024-08-27",
          "2024-08-28",
          "2024-08-29",
          "2024-08-30",
          "2024-08-31"
        ],
        [
          "2024-08-18",
          "2024-08-19",
          "2024-08-20",
          "2024-08-21",
          "2024-08-22",
          "2024-08-23",
          "2024-08-24",
          "2024-08-25",
          "2024-08-26",
          "2024-08-27",
          "2024-08-28",
          "2024-08-29",
          "2024-08-30",
          "2024-08-31"
        ],
        [
          "2024-08-18",
          "2024-08-19",
          "2024-08-20",
          "2024-08-21",
          "2024-08-22",
          "2024-08-23",
          "2024-08-24",
          "2024-08-25",
          "2024-08-26",
          "2024-08-27",
          "2024-08-28",
          "2024-08-29",
          "2024-08-30",
          "2024-08-31"
        ]
      ],
      "yData": [
        [
          94.18181818181819,
          96.68965517241378,
          96.30645161290323,
          90.98333333333333,
          93.859375,
          91.58064516129032,
          92.25806451612902,
          95.12500000000001,
          98.71666666666665,
          99.58333333333333,
          96.83333333333333,
          94.45161290322581,
          90.45454545454545,
          94.71875
        ],
        [
          91.61363636363636,
          96.29310344827586,
          94.84677419354838,
          93.08333333333333,
          91.3984375,
          91.07258064516128,
          95.5967741935484,
          93.02678571428572,
          93.7,
          98.95833333333333,
          99.52777777777779,
          92.39516129032259,
          94.09848484848484,
          99.4453125
        ],
        [
          97.42424242424242,
          91.65517241379311,
          96.7741935483871,
          91.56666666666666,
          93.28125,
          94.5483870967742,
          99.38709677419355,
          93.14285714285715,
          94.33333333333333,
          97.73333333333333,
          99.62962962962963,
          91.16129032258065,
          96.84848484848486,
          93.4375
        ]
      ],
      "xlabel": "Datum",
      "ylabel": "Genauigkeit",
      "labels": [
        "Maissilage",
        "Grassilage",
        "Mineral"
      ]
    },
    "AccuracyPerDriver": {
      "title": "Fütterungsgenauigkeit der Fahrer",
      "xData": [
        [
          "2024-08-18T14:59:18.804000",
          "2024-08-21T15:03:28.096000",
          "2024-08-25T14:56:06.788000",
          "2024-08-26T14:54:01.046000",
          "2024-08-31T15:01:07.641000"
        ],
        [
          "2024-08-19T15:00:23.652000",
          "2024-08-20T15:05:32.975000",
          "2024-08-23T15:05:03.107000",
          "2024-08-27T14:55:20.265000",
          "2024-08-29T14:55:03.131000"
        ],
        [
          "2024-08-22T15:05:43.120000",
          "2024-08-24T15:03:42.176000",
          "2024-08-28T15:00:07.269000",
          "2024-08-30T15:00:40.405000"
        ]
      ],
      "yData": [
        [
          94.40656565656566,
          91.87777777777778,
          93.76488095238096,
          95.58333333333333,
          95.8671875
        ],
        [
          94.87931034482759,
          95.9758064516129,
          92.40053763440861,
          98.75833333333333,
          92.66935483870968
        ],
        [
          92.84635416666667,
          95.74731182795699,
          98.66358024691358,
          93.80050505050505
        ]
      ],
      "xlabel": "Datum",
      "ylabel": "Genauigkeit",
      "labels": [
        "Horst",
        "Marie",
        "Rudi"
      ]
    }
  }
}
