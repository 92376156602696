import { useContext, useEffect, useState } from "react"
import { Link, useParams, useRouteLoaderData } from "react-router-dom"
import { Button, Card, Collection } from "@aws-amplify/ui-react"
import { FaPenToSquare, FaXmark } from "react-icons/fa6"

import { Organization, UserDataDto } from "sds"

import { isProd } from "../../config"
import { UserDataHelper } from "../../api/userData"
import { AppContext, AppContextHelper } from "../../AppContext"
import { getLinkToOrg } from "../../LinkUtils"

import Address from "./-components/Address"
import { imageHash } from "./-components/ListItem"
import RecipeItem from "./-components/RecipeItem"

import './RecipeManagement.css'

const NUM_ORG_IMAGES = 3
const IMAGE_HASH_SEED = 27 /* intentional, works better for test scenario than the default 271 */

export default function OrgDashboard() {
  const params = useParams()
  const rootData = useRouteLoaderData("root") as UserDataDto
  // console.log("🧪 OrgDashboard / rootData:", rootData)
  const [org, setOrg] = useState<Organization | undefined>(undefined)

  const appCtx = useContext(AppContext)

  useEffect(() => {
    console.log("> OrgDashboard.useEffect (setActiveOrgId)")
    if (!org) {
      // console.log("❌ OrgDash.useEffect (setActiveOrgId) org is empty")
      return
    }
    // console.log("OrgDash.useEffect (setActiveOrgId) setting user preference to ", org.id)
    AppContextHelper.setUserPreference(appCtx, AppContextHelper.CacheKeys.UserPreferences.ActiveOrgId, org.id)
  }, [org, appCtx])

  if (!rootData) {
    return isProd
      ? <>Lade Daten ...</>
      : <>Loading (rootData) ...</>
  }

  let org_: Organization | undefined = undefined
  if (rootData) {
    org_ = UserDataHelper.findOrg(params.orgId!, rootData)
  }
  if (!org) {
    setOrg(org_)
  }
  if (!org_ || !org) {
    return isProd
      ? <>Lade Daten ...</>
      : <>Loading (org) ...</>
  }

  const recipes = AppContextHelper.findRecipes(org)
  const imageIdx = imageHash(IMAGE_HASH_SEED, org.id, NUM_ORG_IMAGES)
  return <>
    <div className="rm-view-page">
      <div className="hdr-img" style={{
        background: `transparent url('/unlicensed/betrieb-${imageIdx}.jpg') 0% 0% no-repeat padding-box`
      }} />
      <Link to='..'>
        <Button className="button-close has-icon"><span role="img" aria-label="Schließen"><FaXmark /></span></Button>
      </Link>
      <div className="content-overlay">
        <div className="header">
          <div className="info">
            <h2>{org.name}</h2>
            <div className="basic-info">
              <Address address={org.address} />
            </div>
          </div>
          <Link to={getLinkToOrg(org, true)}>
            <Button className="button-edit has-icon"><span role="img" aria-label="Bearbeiten"><FaPenToSquare /></span></Button>
          </Link>
        </div>

        <Collection className="details" type="list" direction="row"
          items={["Statistiken und Fahrer", "Map"]}>
          {(item, index) => {
            return <Card key={item} className="!p-2 !border !border-gray-400 !rounded-[5px]" style={{ width: "50%" }}>
              <div className="font-semibold text-[16px]">{item}</div>
              <div className="text-[12px]">
                ...<br />
                Platzhalter<br />
                ...
              </div>
            </Card>
          }}
        </Collection>
      </div>

      <div className="content-overlay mt-6">
        <div className="font-semibold text-[16px]">Meine Rezepte</div>
        <Collection className="details w-full" type="list" direction="row"
          items={recipes}>
          {(item, index) => {
            return <RecipeItem className="!w-full !border-none" key={item.id} org={org} item={item} />
          }}
        </Collection>
      </div>
    </div>

    {/* <small>* Die Icons "pen-to-square (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.</small> */}
  </>
}
