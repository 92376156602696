import { Link, NavLink } from 'react-router-dom'
import { Button, Flex } from '@aws-amplify/ui-react'
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar'
import { FaArrowUpRightFromSquare, FaChevronDown } from 'react-icons/fa6'

import { AppContextHelper, IAppContext } from '../AppContext'
import RefreshUserDataButton from './RefreshUserDataButton'

import './MainMenu.css'

interface MainMenuProps {
  appCtx: IAppContext
  activeOrgId?: string
}

const MainMenu = ({ activeOrgId, appCtx }: MainMenuProps) => {
  // console.log("MainMenu: activeOrgId", activeOrgId)
  if (!appCtx) {
    console.log("MainMenu: !appCtx")
  }
  if (!appCtx.userData) {
    console.log("MainMenu: !appCtx.userData")
  }
  if (!activeOrgId) {
    console.log("MainMenu: !activeOrgId")
  }
  const activeOrg = appCtx && appCtx.userData && activeOrgId
    ? AppContextHelper.findOrg(activeOrgId, appCtx)
    : undefined
  // console.log("MainMenu (2): activeOrg", activeOrg)
  return <Sidebar id="main-menu"
    className='!border-r !border-[#d9d9d9]'>
    <Flex className='!px-5 !pb-4 !flex-col !gap-2.5'>
      {/* TODO: Flex's border-bottom cannot be set using tailwind classes */}
      <RefreshUserDataButton />

      {/* fake drop-down */}
      <Link to="/orgs/" style={{
        textDecorationLine: "none"
      }}>
        <Button className='select-org-button'>
          <Flex direction={'row'}>
            {activeOrg?.name}
            <FaChevronDown />
          </Flex>
        </Button>
      </Link>
    </Flex>

    <Menu renderExpandIcon={() => <></>}
      className='border-t border-[#d9d9d9] p-2'
      menuItemStyles={{
        button: {
          // the active class will be added automatically by react router
          // so we can use it to style the active menu item
          [`&.active`]: {
            backgroundColor: '#F6F8FB',
            color: '#25C401',
            borderLeft: '2px solid #25C401'
          },
        },
      }}>
      <MenuItem component={<NavLink to={`/orgs/${activeOrgId}`} end />}>Übersicht</MenuItem>
      <MenuItem component={<NavLink to="/vehicles/" end />}>Maschinen</MenuItem>
      <SubMenu label="Rezeptverwaltung" defaultOpen>
        <MenuItem component={<NavLink to={`/orgs/${activeOrgId}/feed-components/`} />}>Komponenten</MenuItem>
        <MenuItem component={<NavLink to={`/orgs/${activeOrgId}/animal-groups/`} />}>Ausladestellen</MenuItem>
        <MenuItem component={<NavLink to={`/orgs/${activeOrgId}/recipes/`} />}>Rezepte</MenuItem>
        <MenuItem component={<NavLink to={`/orgs/${activeOrgId}/stats`} />}>Statistiken</MenuItem>
      </SubMenu>
      <MenuItem component={<NavLink to="/training/" />}>Training</MenuItem>
      <MenuItem component={<NavLink to="https://strautmann.com/strautmannshop" />}>Shop <FaArrowUpRightFromSquare className='inline' /></MenuItem>
      <MenuItem component={<NavLink to="https://strautmann.com/ersatzteilservice" />}>Ersatzteile <FaArrowUpRightFromSquare className='inline' /></MenuItem>
      <MenuItem component={<NavLink to="/admin/" />}>Admin</MenuItem>
    </Menu>

    <div className='absolute w-full bottom-16'>
      <Menu className='border-t border-[#d9d9d9] p-2'>
        <MenuItem component={<NavLink to="https://strautmann.com/kundendienst" />}>Kontakt <FaArrowUpRightFromSquare className='inline' /></MenuItem>
        <MenuItem component={<NavLink to="https://strautmann.com/kundendienst" />}>Support <FaArrowUpRightFromSquare className='inline' /></MenuItem>
      </Menu>
    </div>
  </Sidebar>
}

export default MainMenu
