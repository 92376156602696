/**
 * @note The interfaces in this file are more or less copied from the supabase generated types in the users-svc.
 */

import { createContext } from 'react';
import { AnimalGroupDto, FeedComponentDto, Organization, Recipe, UserDataDto } from "sds"

export interface IReloadUserDataFunc {
  (): Promise<boolean>
}
export interface IAppContext {
  userData?: UserDataDto
  userPreferences?: {
    [moar: string]: string
  }
  setUserPreference?: (key: string, value: string) => void
  reloadUserData?: IReloadUserDataFunc
}

export const AppContext = createContext<IAppContext>({
  userData: {} as UserDataDto,
  userPreferences: {},
  setUserPreference: (key: string, value: string) => { },
  reloadUserData: undefined
})

export class AppContextHelper {

  static setUserPreference(ctx: IAppContext, key: string, value: string) {
    // console.log("> AppContextHelper.setUserPreference")
    const oldValue = ctx.userPreferences?.[key]
    if (oldValue === value) {
      // console.log("  skip, because equal")
      return
    }
    // console.log("  setUserPreference", ctx.userPreferences)
    ctx.setUserPreference!(key, value)
    // console.log("  setUserPreference", ctx.userPreferences) // note: this still has old state
  }

  static findOrgs(ctx: IAppContext): Organization[] {
    const orgs = ctx.userData!.Organizations
    return orgs
  }

  static findOrg(orgId: string, ctx: IAppContext): Organization {
    const orgs = ctx.userData!.Organizations
    const orgsFiltered = orgs.filter(o => o.id === orgId)
    if (!orgsFiltered
      || 1 !== orgsFiltered.length) {
      throw new Error("No or invalid data")
    }
    return orgsFiltered[0]
  }

  static findAnimalGroups(org: Organization): AnimalGroupDto[] {
    return org.Ausladegruppen
  }

  static findAnimalGroup(org: Organization, agId: string): AnimalGroupDto {
    const agsFiltered = org.Ausladegruppen.filter(ag => ag.id === agId)
    if (!agsFiltered
      || 1 !== agsFiltered.length) {
      throw new Error("No or invalid data")
    }
    return agsFiltered[0]
  }

  static findFeedComponents(org: Organization): FeedComponentDto[] {
    return org.FeedComponents
  }

  static findFeedComponent(org: Organization, feedComponentId: string): FeedComponentDto {
    const fcsFiltered = org.FeedComponents.filter(fc => fc.id === feedComponentId)
    if (!fcsFiltered
      || 1 !== fcsFiltered.length) {
      throw new Error("No or invalid data")
    }
    return fcsFiltered[0]
  }

  static findRecipes(org: Organization): Recipe[] {
    return org.Recipes
  }

  static findRecipe(org: Organization, recipeId: string): Recipe {
    const recipes = this.findRecipes(org)
    const recipesFiltered = recipes.filter(r => r.id === recipeId)
    if (!recipesFiltered
      || 1 !== recipesFiltered.length) {
      throw new Error("No or invalid data")
    }
    return recipesFiltered[0]
  }
}

export namespace AppContextHelper {
  export namespace CacheKeys {
    export enum UserPreferences {
      ActiveOrgId = "sds:orgs:activeOrgId"
    }
  }
}
