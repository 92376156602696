/**
 * Properties of an Organization (Betrieb / Hof / ...).
 * 
 * @note The interfaces in this file are more or less copied from the supabase generated types in the users-svc.
 */

import { InferType, ValidationError, array, lazy, number, object, string } from "yup"

import { AusladegruppeSchema, FeedComponentSchema, RecipeSchema } from "."

const AddressSchema = lazy((value) => {
  switch (typeof value) {
    case 'object':
      return object({
        street: string(),
        number: string(),
        zip: string(),
        city: string(),
        country: string(),
        gps: object({
          lat: number().required().positive(),
          lng: number().required().positive()
        }).optional()
      }).required()
    case 'string':
      return string().required()
    default:
      throw new ValidationError("Address does not satisfy AddressSchema")
  }
})


export const OrganizationSchema = object({
  address: AddressSchema,
  created_at: string().required(),
  created_by: string().required(),
  ext_id: string(),
  id: string().required(),
  name: string().required(),
  updated_at: string().defined(),
  updated_by: string().defined(),

  FeedComponents: array(FeedComponentSchema).required(),
  Ausladegruppen: array(AusladegruppeSchema).required(),
  Recipes: array(RecipeSchema).required(),

  // [moarProps: string]: any
})

export type Organization = InferType<typeof OrganizationSchema>
