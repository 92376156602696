import { useEffect, useState } from 'react'
import { LoaderFunction, Navigate, NavigateFunction, Outlet, useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { signOut } from 'aws-amplify/auth'
import { Cache, I18n } from 'aws-amplify/utils'
import { Authenticator, Theme, ThemeProvider, translations, useAuthenticator } from '@aws-amplify/ui-react'

import { UserDataDto } from 'sds'

import { configureApis } from './api/utils'
import { getCurrentUserData } from './api/userData'
import MainMenu from './components/MainMenu'
import { AppContext, AppContextHelper, IAppContext } from './AppContext'
import { isDev, isProd } from './config'
import amplifyconfig from './amplifyconfiguration.json'

import './tw-init.css'
import '@aws-amplify/ui-react/styles.css'
// Supports weights 100-900
import '@fontsource-variable/inter'
import './global.css'
import './App.css'

Amplify.configure(amplifyconfig)

configureApis()

I18n.putVocabularies(translations)

const sdsTheme: Theme = {
  name: 'sds-theme',
  tokens: {
    // colors: {
    //   font: {
    //     primary: { value: '#008080' },
    //   },
    // },
    components: {
      button: {
        // default style: color, border dark
        _hover: {
          color: 'var(--bsl-green)',
          backgroundColor: 'var(--green-lighter-amp10)',
          borderColor: 'var(--bsl-green)',
        },
        _focus: {
          color: '{components.button._hover.color}',
          backgroundColor: '{components.button._hover.backgroundColor}',
          borderColor: '{components.button._hover.borderColor}',
        },
        _active: {
          color: '{components.button._hover.color}',
          backgroundColor: 'var(--green-lighter-amp20)',
          borderColor: '{components.button._hover.borderColor}',
        },
        primary: {
          color: 'white',
          backgroundColor: 'var(--bsl-green)',
          borderColor: 'var(--bsl-green)',
          _hover: {
            color: '{components.button.primary.color}',
            backgroundColor: 'var(--bsl-green-darker-10)',
            borderColor: '{components.button.primary._hover.backgroundColor}',
          },
          _focus: {
            color: '{components.button.primary._hover.color}',
            backgroundColor: '{components.button.primary._hover.backgroundColor}',
            borderColor: '{components.button.primary._focus.backgroundColor}',
          },
          _active: {
            backgroundColor: 'var(--bsl-green-darker-20)',
            borderColor: '{components.button.primary._active.backgroundColor}',
          },
        },
        link: {
          color: 'var(--bsl-green)',
          _hover: {
            color: '{components.button.link.color}',
            backgroundColor: '{components.button._hover.backgroundColor}',
          },
          _focus: {
            color: '{components.button.link.color}',
            backgroundColor: '{components.button._hover.backgroundColor}',
          },
          _active: {
            color: '{components.button.link.color}',
            backgroundColor: '{components.button._active.backgroundColor}',
            borderColor: '{components.button._active.backgroundColor}',
          },
        },
      },
      fieldcontrol: {
        _focus: {
          borderColor: 'var(--bsl-green)',
          boxShadow: '0px 0px 0px 2px var(--bsl-green)', // Dimensions are Amplify UI defaults
        }
      },
      tabs: {
        item: {
          _hover: {
            color: 'var(--bsl-green)',
          },
          _focus: {
            color: 'var(--bsl-green)',
          },
          _active: {
            color: 'var(--bsl-green)',
            borderColor: 'var(--bsl-green)',
            backgroundColor: 'var(--green-lighter-amp10)'
          }
        }
      }
    },
  },
}

const reloadUserData = async (): Promise<any> => {
  console.log("> reloadUserData")
  return await getCurrentUserData()
}

/**
 *  @note: `AppAuthSwitcher` must be its own component
 * (vs. inlined in the `App` function below) to be able to use the `useAuthenticator` hook.
 */
function AppAuthSwitcher() {
  const location = useLocation()
  const { authStatus } = useAuthenticator(context => [context.authStatus])

  if (authStatus === "configuring") {
    return <>Lade Daten ...</>
  }

  if (location.pathname.startsWith('/login')) {
    // console.log("🔑 AppAuthSwitcher: Rendering public login route")
    return <Outlet />
  }

  if (authStatus !== 'authenticated') {
    // console.log(`🔑 AppAuthSwitcher: Redirect to login (authStatus === ${authStatus})`)
    return <Navigate
      to={`/login`}
      state={{ from: location }}
      replace />
  } else {
    // console.log("🔑 AppAuthSwitcher: Rendering private route")
    return <AppAuthenticated />
  }
}

const getSignOutHandler = (navigate: NavigateFunction) => {
  return () => {
    console.log("> custom sign out handler")
    signOut()
    navigate('/')
    if (!isProd) {
      console.log("  DEV: clearing cache")
      Cache.clear()
    }
  }
}

function AppAuthenticated() {
  const env = process.env.NODE_ENV.toLowerCase()
  const navigate = useNavigate()
  const { authStatus } = useAuthenticator(context => [context.authStatus])
  const userData = useLoaderData() as UserDataDto
  // console.log("AppAuthenticated: loader user data =", userData)

  const [userPreferences, setUserPreferences] = useState<{ [moar: string]: string } | undefined>(undefined)
  const [appCtxData, setAppCtxData] = useState<IAppContext>({
    userData: userData,
    userPreferences: userPreferences,
  })
  const [activeOrgId, setActiveOrgId] = useState<string | undefined>(appCtxData.userPreferences?.[AppContextHelper.CacheKeys.UserPreferences.ActiveOrgId])
  const [reloadRequired, setReloadRequired] = useState(false)

  const setUserPreference = (key: string, value: string) => {
    // console.log("> ✅ App.setUserPreference")
    // console.log("  setUserPreference (before)", userPreferences)
    const updated = {
      ...userPreferences,
      [key]: value
    }
    // console.log("  ✅ App.setUserPreference: storing to LS", updated)
    localStorage.setItem("sds:userPreferences", JSON.stringify(updated))
    // console.log("  setUserPreferences (updated)", updated)
    setUserPreferences(updated)

    setAppCtxData({
      ...appCtxData,
      userPreferences: updated // use "updated", as userPreferences still has the old state
    })
  }
  if (!appCtxData.setUserPreference) {
    // console.log(`✅ AppAuthenticated: store callback in ctx`)
    setAppCtxData({
      ...appCtxData,
      setUserPreference: setUserPreference
    })
  }

  if (authStatus === 'authenticated' && !userData) {
    if (!reloadRequired) {
      // console.log("AppAuthenticated.useEffect (do-navigate) -> really set should reload")
      setReloadRequired(true)
    }
  }

  useEffect(() => {
    // console.log("AppAuthenticated.useEffect (do-navigate): authStatus =", authStatus)
    if (reloadRequired) {
      // console.log("AppAuthenticated.useEffect (do-navigate) -> should reload")
      setReloadRequired(false)
      setTimeout(() => { navigate('.', { state: null, replace: true }) }, 100)
    }
  }, [authStatus, navigate, reloadRequired])

  useEffect(() => {
    // console.log("> AppAuthenticated.useEffect (getActiveOrgId)")
    if (!appCtxData.userPreferences) {
      // console.log("  AppAuthenticated.useEffect (getActiveOrgId): skip, userPref not loaded, yet")
      return
    }
    const _activeOrgId = appCtxData.userPreferences?.[AppContextHelper.CacheKeys.UserPreferences.ActiveOrgId]
    // console.log("_activeOrgId:", _activeOrgId)
    if (_activeOrgId) {
      console.log("✅ Active org found:", _activeOrgId)
      setActiveOrgId(_activeOrgId)
    } else {
      console.log("⚡ No active org found, selecting the first one.")
      if (!appCtxData.userData) {
        console.log("❌ No userData in ctx.")
      }
      else {
        const firstOrg = AppContextHelper.findOrgs(appCtxData)[0]
        // console.log("firstOrg:", firstOrg.id)
        // console.log("calling setActiveOrgId")
        setActiveOrgId(firstOrg.id)
        // console.log("calling setUserPreference")
        AppContextHelper.setUserPreference(appCtxData, AppContextHelper.CacheKeys.UserPreferences.ActiveOrgId, firstOrg.id)
      }
    }
  }, [appCtxData])

  useEffect(() => {
    // console.log("App.useEffect (user data -> context)")
    if (userData && !appCtxData.userData) {
      // console.log("Got user data, updating CONTEXT")
      setAppCtxData({
        ...appCtxData,
        userData: userData,
      })
    }
  }, [appCtxData, userData])

  useEffect(() => {
    // console.log("App.useEffect (getInitialUserPreferences)")
    const getInitialUserPreferences = () => {
      console.log("> getInitialUserPreferences")
      if (userPreferences) {
        // console.log("  getInitialUserPreferences: has userPref", userPreferences)
        return
      }
      const lsValue = localStorage.getItem("sds:userPreferences")
      if (!lsValue) {
        console.log("  getInitialUserPreferences: no userPref in LS -> setting empty")
        setUserPreferences({})
        setAppCtxData({
          ...appCtxData,
          userPreferences: {}
        })
        return
      }
      const fromLS = JSON.parse(lsValue)
      console.log("  ✅ getInitialUserPreferences: found userPref in LS", fromLS)
      setUserPreferences(fromLS)
      setAppCtxData({
        ...appCtxData,
        userPreferences: fromLS // use "fromLS", as userPreferences still has the old state
      })
      console.log("  ✅ getInitialUserPreferences: set state to userPref from LS")
    }

    getInitialUserPreferences()
  }, [appCtxData, userPreferences])

  return <>
    <header className="app-header">
      <h1 className='flex gap-x-6 items-center'>
        <img src="/logo-strautmann.svg" alt="Strautmann" />
        {isProd
          ? ''
          : <div>{`SDS /${isDev ? 'dev' : env}`}</div>
        }
      </h1>

      <div className='user-account flex justify-end items-center gap-x-3'>
        {/* TODO: avatar image */}
        <div>(AV)</div>
        <div className='flex items-center gap-x-1'>
          {/* {user?.username} */}
          {appCtxData.userData?.first_name} {appCtxData.userData?.last_name}
          <svg className='inline' width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_140_164" maskUnits="userSpaceOnUse" x="0" y="0" width="13" height="8">
              <path d="M13 7.6547V0.345703L0.819 0.345703V7.6547L13 7.6547Z" fill="white" />
            </mask>
            <g mask="url(#mask0_140_164)">
              <path d="M2.03662 1.56348L6.90862 6.43548L11.7816 1.56348" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </svg>
        </div>
        <div><button onClick={getSignOutHandler(navigate)}>Abmelden</button></div>
      </div>
    </header>
    <MainMenu appCtx={appCtxData} activeOrgId={activeOrgId} />
    <main className='main-container'>
      <AppContext.Provider value={appCtxData}>
        <Outlet />
      </AppContext.Provider>
    </main>
  </>
}

export default function App() {
  return <ThemeProvider theme={sdsTheme}>
    <Authenticator.Provider>
      <AppAuthSwitcher />
    </Authenticator.Provider>
  </ThemeProvider>
}

export const loader: LoaderFunction = async (args): Promise<any> => {
  console.log("> App.loader")
  const userData = await reloadUserData()
  if (userData) {
    console.log("  🎉 got user data")
  }
  console.log("🧪 Patching address data")
  const ud = userData as UserDataDto
  if (ud && ud.Organizations) {
    ud.Organizations.forEach(org => {
      if (org.name === "Haggeney") {
        org.address = {
          street: "Bielefelder Straße",
          number: "53",
          zip: "49196",
          city: "Bad Laer",
          country: "de",
          gps: {
            lat: 52.102019,
            lng: 8.101101
          }
        }
      }
      if (org.name === "Große Börding") {
        org.address = {
          street: "Heidestraße",
          number: "9",
          zip: "49219",
          city: "Glandorf",
          country: "de",
          gps: {
            lat: 52.097583,
            lng: 7.998280
          }
        }
      }
    })
  }
  return userData
}

export const Component = App
