import { Badge, Flex } from "@aws-amplify/ui-react"

import { Organization, Recipe } from "sds"

import { UNIT_OF_MASS } from "../../../org-settings"
import { AppContextHelper } from "../../../AppContext"
import { getLinkToOrgRecipe } from "../../../LinkUtils"
import { getTotalFreshMass, getTotalNumberOfAnimals } from "../recipes/Recipes"

import { imageHash, ListItem, StarStates } from "./ListItem"

const NUM_RC_IMAGES = 3
const IMAGE_HASH_SEED = 27 /* intentional, works better for test scenario than the default 271 */

interface RecipeItemProps {
  className?: string
  org: Organization
  item: Recipe
  hasViewButton?: boolean
  hideFavStar?: boolean
}

/**
 * A ListItem representing a Recipe.
 * Used in
 *   - /org/:orgId
 *     - fav-star
 *     - has-view-button
 *     - ag-badges
 *     - animal count
 *     - weight per animal
 *     - total weight
 *   - /animal-groups/:animalGroupId
 *     - ! fav-star
 *     - ! has-view-button
 *     - ag-badges
 *     - animal count
 *     - weight per animal
 *     - total weight
 *   - /feed-components/:feedComponentId
 *     - ! fav-star
 *     - ! has-view-button
 *     - ag-badges
 *     - animal count
 *     - weight per animal
 *     - total weight
 *   - /recipes/:recipeId
 *     - fav-star
 *     - has-view-button
 *     - ag-badges
 *     - animal count
 *     - weight per animal
 *     - total weight
 * @returns 
 */
export default function RecipeItem({ className, org, item, hideFavStar, hasViewButton = true }: RecipeItemProps) {
  const recipe = item as Recipe
  const imageIdx = imageHash(IMAGE_HASH_SEED, recipe.id, NUM_RC_IMAGES)
  const imageSrc = `/unlicensed/rezept-${imageIdx}.jpg`
  const numAnimals = getTotalNumberOfAnimals(org, recipe)
  const freshMassPerAnimal = getTotalFreshMass(org, recipe)
  const details = <>
    <Flex>
      {recipe.RecipesAusladegruppen
        .sort((a, b) => {
          if (a.order && !b.order) {
            return -1
          }
          if (!a.order && b.order) {
            return 1
          }
          if (!a.order && !b.order) {
            return 0
          }
          return (a.order! < b.order! ? -1 : 1)
        })
        .map(ra => {
          const ag = AppContextHelper
            .findAnimalGroup(org, ra.ausladegruppe_id)
          return <Badge size="small" variation="success" key={ag.name}>{ag.name}</Badge>
        })}
    </Flex>
    {`${numAnimals} Tiere`} <br />
    {`Gewicht: ${(freshMassPerAnimal).toLocaleString(undefined, { maximumFractionDigits: 1 })} ${UNIT_OF_MASS} pro Tier`}<br />
    {`Gesamtgewicht: ${(numAnimals * freshMassPerAnimal).toLocaleString(undefined, { maximumFractionDigits: 0 })} ${UNIT_OF_MASS}`}<br />
  </>

  return <ListItem
    key={recipe.id}
    className={`recipe ${className}`}
    title={recipe.name}
    href={getLinkToOrgRecipe(org, recipe.id, false)}
    imageSrc={imageSrc}
    details={details}
    hasViewButton={hasViewButton}
    starState={hideFavStar
      ? StarStates.hidden
      : StarStates.filled} />
}