import { useState } from "react"
import { FeedComponentDto, Organization } from "sds"
import { CURRENCY_SYMBOL, UNIT_OF_MASS } from "../../../org-settings"
import { getLinkToOrgFeedComponent } from "../../../LinkUtils"
import { ListItem } from "./ListItem"
import { ensureImagePath } from "./FeedComponentUtils"

interface FeedComponentProps {
  org: Organization
  item: FeedComponentDto
  details?: string | React.ReactNode
  hasViewButton?: boolean
}

/**
 * A ListItem representing a Feed Component.
 * Used in
 *   - /feed-components/
 *     - ts-gehalt
 *     - preis
 *   - /recipes/:recipeId
 *     - weight (in that recipeId)
 * @returns 
 */
export default function FeedComponentItem({ org, item, details, hasViewButton = true }: FeedComponentProps) {
  const feedComponent = item as FeedComponentDto
  const [imageSrc, setImageSrc] = useState<string>("")

  ensureImagePath(feedComponent, imageSrc, setImageSrc)

  if (!details) {
    details = <>
      <div>TS-Gehalt: {feedComponent.ts_gehalt_percent
        ? `${feedComponent.ts_gehalt_percent.toLocaleString(undefined, { maximumFractionDigits: 1 })}%`
        : 'nicht angegeben'}</div>
      <div>Preis: {feedComponent.price_per_kg
        ? `${feedComponent.price_per_kg} ${CURRENCY_SYMBOL} / ${UNIT_OF_MASS}`
        : 'nicht angegeben'}</div>
    </>
  }

  return <ListItem
    key={feedComponent.id}
    className="feed-component"
    title={feedComponent.name}
    href={getLinkToOrgFeedComponent(org, feedComponent.id, false)}
    imageSrc={imageSrc}
    details={details}
    hasViewButton={hasViewButton}
    size="small" />
}