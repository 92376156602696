/**
 * Mapping between a Recipe and its FeedComponents.
 * 
 * @note The interfaces in this file are more or less copied from the supabase generated types in the users-svc.
 */

import { InferType, boolean, number, object, string } from "yup"

export const RecipeComponentSchema = object({
  feed_component_id: string().required(),
  mass_kg: number().required().positive(),
  is_mass_dry: boolean().required(),
  mixing_time_s: number().positive(),
  order: number().positive(),
  recipe_id: string().required(),
})

export type RecipeComponent = InferType<typeof RecipeComponentSchema>
