/**
 * Properties of a Recipe.
 * 
 * @note The interfaces in this file are more or less copied from the supabase generated types in the users-svc.
 */

import { InferType, array, object, string } from "yup"

import { RecipeComponent, RecipeComponentSchema, RecipesAusladegruppen, RecipesAusladegruppenSchema } from "."

const CreatedPropsSchema = object({
  created_at: string().required(),
  created_by: string().required()
})
type CreatedProps = InferType<typeof CreatedPropsSchema>
type CreatedPropsKeys = keyof CreatedProps
const UpdatedPropsSchema = object({
  updated_at: string().required(),
  updated_by: string().required()
})
type UpdatedProps = InferType<typeof UpdatedPropsSchema>
type UpdatedPropsKeys = keyof UpdatedProps

/**
 * Summary of the schemas/types and their differences
 *   - Recipe[Schema] - a full recipe (from a schema)
 *   - NewRecipe[Schema] - for creation of a new recipe (from a schema)
 *   - MyNewRecipeSchema - for creation of a new recipe (manual)
 *   - UpdatedRecipeSchema - for updating a recipe (from a schema)
 *     - there is no `UpdatedRecipe` type in this file
 */
export const NewRecipeSchema = object({
  // created_at: string(),
  // created_by: string(),
  // id: string(),
  name: string().required(),
  org_id: string().required(),
  // updated_at: string(),
  // updated_by: string(),

  RecipeComponents: array(RecipeComponentSchema).required(), /** @todo use NewRecipeComponentSchema */
  RecipesAusladegruppen: array(RecipesAusladegruppenSchema).required(), /** @todo use NewRecipesAusladegruppenSchema */
})

export const UpdatedRecipeSchema = object({
  created_at: string().required(),
  created_by: string().required(),
  id: string().required(),
  name: string().required(),
  org_id: string().required(),
  // updated_at: string(),
  // updated_by: string(),
  deleted_at: string().nullable(),
  deleted_by: string().nullable(),

  RecipeComponents: array(RecipeComponentSchema).required(),
  RecipesAusladegruppen: array(RecipesAusladegruppenSchema).required()
})

export const RecipeSchema = object({
  created_at: string().required(),
  created_by: string().required(),
  id: string().required(),
  name: string().required(),
  org_id: string().required(),
  updated_at: string().defined(),
  updated_by: string().defined(),
  deleted_at: string().nullable(),
  deleted_by: string().nullable(),

  RecipeComponents: array(RecipeComponentSchema).required(),
  RecipesAusladegruppen: array(RecipesAusladegruppenSchema).required()
})

export type NewRecipe = InferType<typeof NewRecipeSchema>
export type Recipe = InferType<typeof RecipeSchema>

type MyNewRecipesAusladegruppen = Omit<
  RecipesAusladegruppen,
  "recipe_id" | CreatedPropsKeys | UpdatedPropsKeys>
type MyNewRecipeComponent = Omit<
  RecipeComponent,
  "recipe_id" | CreatedPropsKeys | UpdatedPropsKeys>
export type MyNewRecipe = Omit<
  Recipe,
  "id" | CreatedPropsKeys | UpdatedPropsKeys | "RecipesAusladegruppen" | "RecipeComponents"
> /* & Partial<UpdatedProps> */ & {
  RecipesAusladegruppen: MyNewRecipesAusladegruppen[],
  RecipeComponents: MyNewRecipeComponent[]
}

// type Prettify<T> = {
//   [K in keyof T]: T[K];
// } & {};
// type asdf = Prettify<MyNewRecipeComponent>
// type asdf2 = Prettify<MyNewRecipe>
