import { Navigate } from "react-router-dom"
import { getLinkToOrgById } from "../LinkUtils"
import { useContext } from "react"
import { AppContext, AppContextHelper } from "../AppContext"

export default function HomepageRedirect() {
  const appCtx = useContext(AppContext)
  // console.log("appCtx:", appCtx)
  const activeOrgId = appCtx.userPreferences?.[AppContextHelper.CacheKeys.UserPreferences.ActiveOrgId]
  if (!activeOrgId) {
    return <>Hmmm, keine actorgid</>
  }
  return <Navigate to={getLinkToOrgById(activeOrgId, false)} />
}
