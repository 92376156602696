import { getCurrentUser } from "aws-amplify/auth"
import { Cache } from 'aws-amplify/utils'

import { AnimalGroupDto, FeedComponentDto, Organization, Recipe, UserDataDto } from "sds"
import { isProd } from "../config"

import { fetchFromApi, getCacheKey } from "./utils"

const EXPIRES_IN_MS = 10 * 60 * 1000

export const getCurrentUserData = async (): Promise<any> => {
  console.log("> getCurrentUserData")
  const apiName = 'frontendApi'
  const path = '/users/current'
  const cacheKey = getCacheKey(apiName, path)

  // Only load if the user is signed in.
  try {
    const currentUser = await getCurrentUser()
    // console.log("  got signed in user:", currentUser)
  } catch (err) {
    // console.log("  cannot load user data: No signed in user.")
    if (!isProd) {
      console.log("  DEV: clearing cache")
      Cache.clear()
    }
    // console.log("  no signed in user:", err)
    // console.log("❌ getCurrentUserData: RETURN NULL")
    return null
  }

  try {
    // console.log('  try to get item from cache')
    // console.warn('  remove item from cache') // DBG
    // await Cache.removeItem(cacheKey) // DBG
    let data = await Cache.getItem(cacheKey)
    if (data) {
      console.log('Found in cache:', data, `${cacheKey}`)
    } else {
      console.log(cacheKey, 'not found in cache. Loading from API...')
      data = await fetchFromApi(apiName, path)
      await Cache.setItem(cacheKey, data, { expires: new Date().getTime() + EXPIRES_IN_MS })
      console.log('Fetched from api:', data)
    }
    // console.log("✅ getCurrentUserData: data")
    return await data
  }
  catch (err) {
    console.error('Error fetching user data from cache (unlikely) or api (likely).')
    console.log("api name =", apiName)
    console.log('Error:', err)
    // console.log("❌ getCurrentUserData: throw")
    throw err
  }
}

export class UserDataHelper {

  static findOrgs(userData: UserDataDto): Organization[] {
    const orgs = userData.Organizations
    return orgs
  }

  static findOrg(orgId: string, userData: UserDataDto): Organization {
    try {
      const orgs = userData.Organizations
      const orgsFiltered = orgs.filter(o => o.id === orgId)
      if (!orgsFiltered
        || 1 !== orgsFiltered.length) {
        throw new Error("No or invalid data")
      }
      return orgsFiltered[0]
    } catch (err) {
      // console.log("findOrg / err:", err)
      // console.log("findOrg / err:", userData)
      throw err
    }
  }

  static findAnimalGroups(org: Organization): AnimalGroupDto[] {
    return org.Ausladegruppen
  }

  static findAnimalGroup(org: Organization, agId: string): AnimalGroupDto {
    const agsFiltered = org.Ausladegruppen.filter(ag => ag.id === agId)
    if (!agsFiltered
      || 1 !== agsFiltered.length) {
      throw new Error("No or invalid data")
    }
    return agsFiltered[0]
  }

  static findFeedComponents(org: Organization): FeedComponentDto[] {
    return org.FeedComponents
  }

  static findFeedComponent(org: Organization, feedComponentId: string): FeedComponentDto {
    const fcsFiltered = org.FeedComponents.filter(fc => fc.id === feedComponentId)
    if (!fcsFiltered
      || 1 !== fcsFiltered.length) {
      throw new Error("No or invalid data")
    }
    return fcsFiltered[0]
  }

  static findRecipes(org: Organization): Recipe[] {
    return org.Recipes
  }

  static findRecipe(org: Organization, recipeId: string): Recipe {
    const recipes = this.findRecipes(org)
    const recipesFiltered = recipes.filter(r => r.id === recipeId)
    if (!recipesFiltered
      || 1 !== recipesFiltered.length) {
      throw new Error("No or invalid data")
    }
    return recipesFiltered[0]
  }
}
