import { useContext } from 'react'
import { FaHouse } from 'react-icons/fa6'

import { Organization } from 'sds'

import { AppContext, AppContextHelper } from '../../AppContext'
import Address from './-components/Address'
import { imageHash, ListItem } from './-components/ListItem'
import { ListPage } from './-components/ListPage'

const NUM_ORG_IMAGES = 3
const IMAGE_HASH_SEED = 27 /* intentional, works better for test scenario than the default 271 */

export default function Orgs() {
  const appCtx = useContext(AppContext)
  const orgs = AppContextHelper.findOrgs(appCtx)

  const renderItem = (item: any, index: number): React.ReactElement => {
    const org = item as Organization
    const imageIdx = imageHash(IMAGE_HASH_SEED, org.id, NUM_ORG_IMAGES)
    const imageSrc = `/unlicensed/betrieb-${imageIdx}.jpg`
    return <ListItem
      key={org.id}
      className="org"
      title={org.name}
      href={org.id}
      imageSrc={imageSrc}
      details={<Address address={org.address} />}
      hasEditButton={true} />
  }

  return <>
    <ListPage
      _className='orgs'
      icon={<FaHouse className="w-5 _h-3.5_ relativ text-[#3E9E29]" />}
      title='Meine Betriebe'
      items={orgs.sort((a, b) => a.name < b.name ? -1 : 1)}
      renderFunc={renderItem}
    />

    <small>
      * Die Icons "house-blank", "sliders-up", "eye (light)", "pen-to-square (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.<br />
    </small>
  </>
}
