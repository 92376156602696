import { useContext, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { Button, Flex, Label } from "@aws-amplify/ui-react"
import { del, post, put } from "aws-amplify/api"
import { Cache } from 'aws-amplify/utils'
// import { FaRegTrashCan } from "react-icons/fa6"

import { AnimalGroupCreateDto, AnimalGroupCreateSchema, AnimalGroupDto, AnimalGroupUpdateDto, AnimalGroupUpdateSchema, Organization } from "sds"
import { ResponseMessage } from "../../../../../../@types/sds"

import { currentSession } from "../../../Utils.AmplifyAuth"
import { AppContext, AppContextHelper } from "../../../AppContext"
import { getLinkToOrgAnimalGroups } from "../../../LinkUtils"
import { imageHash } from "../-components/ListItem"
import Modal from "../../../components/Modal"

import '../RecipeManagement.css'

const NUM_AG_IMAGES = 3
const IMAGE_HASH_SEED = 271

type ComponentModes = "create" | "edit" | "invalid"

const getEmptyAnimalGroup = (org: Organization): AnimalGroupDto => {
  return {
    id: '',
    org_id: org.id,
    name: '',
    number_of_animals: 10,
    created_at: '',
    created_by: '',
    updated_at: '',
    updated_by: '',
  }
}

export default function AnimalGroupCreateEdit() {
  const params = useParams()
  const navigate = useNavigate()
  const appCtx = useContext(AppContext)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const isCreateMode = window.location.pathname.endsWith('/new')
  const isEditMode = window.location.pathname.endsWith('/edit')
    && params.orgId
    && params.animalGroupId
  const componentMode: ComponentModes = isCreateMode
    ? "create"
    : isEditMode
      ? "edit"
      : "invalid"
  const modalCallback = async (value: string) => {
    console.log("> modalCallback:", value)
    if (value.trim().toLowerCase() === 'ja') {
      console.log("  wanna delete: " + animalGroup.id)
      await deleteAnimalGroup(animalGroup.id)
      /** @todo set global success message with data from `animalGroup` */

      console.log("  Invalidating Cache")
      const cacheKey = "frontendApi#/users/current"
      await Cache.removeItem(cacheKey)

      if (appCtx.reloadUserData) {
        console.log("  Reloading data")
        await appCtx.reloadUserData()
      } else {
        console.warn("  Not reloading data; callback not set")
      }

      console.log("  Navigating back")
      navigate(getLinkToOrgAnimalGroups(org))
    } else {
      console.log("  NO wanna delete")
    }
    setShowDeleteModal(false)
  }

  const org = AppContextHelper.findOrg(params.orgId!, appCtx)
  const animalGroup: AnimalGroupDto = isEditMode
    ? AppContextHelper.findAnimalGroup(org, params.animalGroupId!)
    : getEmptyAnimalGroup(org)

  const methods = useForm<AnimalGroupCreateDto>({
    values: animalGroup,
    mode: "onBlur"
  })
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors }
  } = methods

  if (componentMode === "invalid") {
    return <div>Errrr... invalid component mode</div>
  }

  const onSubmit: SubmitHandler<AnimalGroupCreateDto> = async (data) => {
    console.log("▶️ onSubmit")
    /** @todo Implement proper client-side validation (different for 'create' and 'update') */
    const isOrigValid = await AnimalGroupCreateSchema.isValid(data)
    if (!isOrigValid) {
      console.warn("❌ Original data is not valid")
      console.log('data', data)
      try {
        const validated: AnimalGroupCreateDto = await AnimalGroupCreateSchema.validate(data, { abortEarly: false })
      } catch (err) {
        if (err instanceof Object
          && err.hasOwnProperty("errors")) {
          /** @ts-ignore */
          console.log("Validation errors (original)", err.errors)
        }
      }
    }
    // else {
    //   console.log("✅ Original data is valid")
    // }
    const cleaned = cleanData(data, isCreateMode)
    // const clean = data
    // console.log('clean', clean)
    // console.log("\n"
    //   + `${clean.name}\n`
    //   + `  Komponenten\n`
    //   + clean.RecipeComponents.map(rco => {
    //     const fcId = rco.feed_component_id
    //     const fc = AppContextHelper.findAnimalGroup(org, fcId)
    //     return `    ${fc.name}\t${rco.mass_kg} kg\t${rco.mixing_time_s} s`
    //   }).join("\n")
    //   + "\n"
    //   + `  Ausladegruppen\n`
    //   + clean.RecipesAusladegruppen.map(rag => {
    //     const agId = rag.ausladegruppe_id
    //     const ag = AppContextHelper.findAusladegruppe(org, agId)
    //     return `    ${ag.name}\t${ag.number_of_animals} T`
    //   }).join("\n")
    // )
    /** @todo Implement proper client-side validation (different for 'create' and 'update') */
    if (!AnimalGroupCreateSchema.isValidSync(cleaned)) {
      console.warn("❌ Cleaned data is not valid")
      try {
        const validated: AnimalGroupCreateDto = await AnimalGroupCreateSchema.validate(cleaned, { abortEarly: false })
      } catch (err) {
        /** @ts-ignore */
        console.log("Validation errors (cleaned)", err.errors)
        const response: ResponseMessage = {
          res: {
            code: "400",
            /** @ts-ignore */
            message: `${err.errors.length} Errors. The first one is: ${err.errors[0]}`
          },
          sessionId: ""
        }
        return response
      }
    }
    else {
      console.log("✅ Cleaned data is valid")
    }
    const result = isEditMode
      ? await updateAnimalGroup(animalGroup.id, cleaned as AnimalGroupUpdateDto)
      : await createAnimalGroup(cleaned as AnimalGroupCreateDto)
    /** @todo Result is meaningless? */
    /** @todo Error handling */

    console.log("Invalidating Cache")
    const cacheKey = "frontendApi#/users/current"
    await Cache.removeItem(cacheKey)

    if (appCtx.reloadUserData) {
      console.log("  Reloading data")
      await appCtx.reloadUserData()
    } else {
      console.warn("  Not reloading data; callback not set")
    }

    console.log("Navigating back")
    navigate(getLinkToOrgAnimalGroups(org))

    return result
  }

  /** Cleans the form data before submission. */
  function cleanData(data: AnimalGroupCreateDto, isCreateMode: boolean): AnimalGroupCreateDto | AnimalGroupUpdateDto {
    console.debug("▶️ cleanData")
    /** @ts-ignore - user input can be string */
    if ("" === data.number_of_animals) {
      data.number_of_animals = 0
    }
    let cleaned
    if (isCreateMode) {
      cleaned = AnimalGroupCreateSchema.cast(data)
    } else {
      cleaned = AnimalGroupUpdateSchema.cast(data)
    }
    return cleaned
  }
  const prepareApiCall = async (path: string, body: any = false) => {
    console.debug("▶️ prepareApiCall")
    const tokens = await currentSession()
    const apiName = 'frontendApi'
    const options = {
      headers: {
        Authorization: `Bearer ${tokens?.idToken}`
      },
      body: body
    }
    if (!body) {
      delete options.body
    }
    console.info(`Prepared API call to ${path}`)
    return { apiName, path, options }
  }
  async function createAnimalGroup(data: AnimalGroupCreateDto) {
    console.log("▶️ createAnimalGroup")
    const apiCallParams = await prepareApiCall(`/animal-groups`, data)
    const restOperation = post(apiCallParams)
    const apiResponse = await (await restOperation.response).body.json() as unknown as ResponseMessage
    console.log("Got API response:", apiResponse)
    console.log("Got restOperation.response:", restOperation.response)
    return apiResponse
  }
  async function updateAnimalGroup(animalGroupId: string, data: AnimalGroupUpdateDto): Promise<ResponseMessage> {
    // -> PUT
    console.log("▶️ updateAnimalGroup")
    const apiCallParams = await prepareApiCall(`/animal-groups/${animalGroupId}`, data)
    const restOperation = put(apiCallParams)
    const apiResponse = await (await restOperation.response).body.json() as unknown as ResponseMessage
    console.log("Got API response:", apiResponse)
    console.log("Got restOperation.response:", restOperation.response)
    return apiResponse
  }
  async function deleteAnimalGroup(animalGroupId: string) {
    console.log("▶️ deleteAnimalGroup", animalGroupId)
    const apiCallParams = await prepareApiCall(`/animal-groups/${animalGroupId}`)
    const restOperation = del(apiCallParams)
    const statusCode: number = (await restOperation.response).statusCode
    console.log("Got API response with statusCode:", statusCode)
    console.log("Got restOperation.response:", restOperation.response)
    return statusCode
  }

  const imageIdx = imageHash(IMAGE_HASH_SEED, animalGroup.id, NUM_AG_IMAGES)

  return <>
    <div className="rm-edit-page">
      <div className="hdr-img" style={{
        background: `transparent url('/unlicensed/stall-${imageIdx}.jpg') 0% 0% no-repeat padding-box`
      }} />
      <div className="content-overlay">
        <div className="header">
          <div className="info">
            <h2>{animalGroup.name}</h2>
          </div>
        </div>

        <div className="details">
          {/* pass all methods into the context */}
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>

              <Flex direction="column" gap="small">
                <Flex direction="row" gap="small">
                  <Label htmlFor="name">Name der Ausladegruppe:</Label>
                  <input
                    {...register("name", { required: "Ausladegruppenname angeben" })}
                    placeholder={animalGroup.name || 'Neue Ausladegruppe'}
                    className={errors?.name ? "error" : ""}
                  />
                  {errors?.name && <div>Fehler: {errors?.name.message}</div>}
                </Flex>

                <Flex direction="row" gap="small">
                  <Label htmlFor="number_of_animals">Anzahl Tiere:</Label>
                  <input
                    {...register("number_of_animals")}
                    className={errors?.number_of_animals ? "error" : ""}
                  />
                </Flex>

                <div className="buttons">
                  <Button variation="primary" type="submit">
                    Speichern
                  </Button>
                  <Link to="..">
                    <Button>Abbrechen</Button>
                  </Link>
                  {componentMode === "edit"
                    && <Button variation="warning" onClick={() => { setShowDeleteModal(true) }}>Löschen</Button>}
                  {componentMode === "edit"
                    && showDeleteModal
                    &&
                    <Modal buttons={[
                      {
                        text: 'Ja',
                        variation: "destructive"
                      },
                      { text: 'Nein' }
                    ]} show={showDeleteModal} callback={modalCallback}>
                      <div className="title">
                        Ausladegruppe wirklich löschen?
                      </div>
                      <div>
                        {`Wollen Sie die Ausladegruppe "${animalGroup.name}" wirklich löschen?`}
                      </div>
                    </Modal>}
                </div>
              </Flex>
            </form>
          </FormProvider>

          {componentMode === "edit"
            &&
            <div className="mt-5 text-xs">
              <div>Zuletzt bearbeitet am {animalGroup.updated_at} von {animalGroup.updated_by}</div>
              <div>Angelegt am {animalGroup.created_at} von {animalGroup.created_by}</div>
            </div>
          }
        </div>
      </div>
    </div>

    {/* <small>* Die Icons "xmark (light)", "pen-to-square (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.</small> */}
  </>
}
