import { useContext } from "react"
import { Link, useParams } from "react-router-dom"
import { Button, Card, Collection, Flex } from "@aws-amplify/ui-react"
import { FaPenToSquare, FaRegStar, FaXmark } from "react-icons/fa6"

import { AnimalGroupDto } from "sds"

import { AppContext, AppContextHelper } from "../../../AppContext"
import { getLinkToOrgAnimalGroup } from "../../../LinkUtils"

import { imageHash } from "../-components/ListItem"
import RecipeItem from "../-components/RecipeItem"

import '../RecipeManagement.css'

const NUM_AG_IMAGES = 3
const IMAGE_HASH_SEED = 271

export default function AnimalGroup() {
  const params = useParams()

  const appCtx = useContext(AppContext)
  const org = AppContextHelper.findOrg(params.orgId!, appCtx)
  const animalGroup: AnimalGroupDto = AppContextHelper.findAnimalGroup(org, params.animalGroupId!)

  const recipesFiltered = AppContextHelper.findRecipes(org)
    .filter(r => {
      let recipeContainsAg = false
      r.RecipesAusladegruppen.forEach(rag => {
        if (rag.ausladegruppe_id === animalGroup.id) {
          recipeContainsAg = true
          return
        }
      })
      return recipeContainsAg
    })
  const imageIdx = imageHash(IMAGE_HASH_SEED, animalGroup.id, NUM_AG_IMAGES)

  return <>
    <div className="rm-view-page">
      <div className="hdr-img" style={{
        background: `transparent url('/unlicensed/stall-${imageIdx}.jpg') 0% 0% no-repeat padding-box`
      }} />
      <Link to='..'>
        <Button className="button-close has-icon"><span role="img" aria-label="Schließen"><FaXmark /></span></Button>
      </Link>
      <div className="content-overlay">
        <div className="header">
          <div className="info">
            <h2>{animalGroup.name} <FaRegStar /></h2>
            <div className="basic-info">
              {animalGroup.number_of_animals} Tiere
            </div>
          </div>
          <Link to={getLinkToOrgAnimalGroup(org, animalGroup.id, true)}>
            <Button className="button-edit has-icon"><span role="img" aria-label="Bearbeiten"><FaPenToSquare /></span></Button>
          </Link>
        </div>

        <Flex direction={"row"}>
          <Card className="!p-2" style={{ width: "50%" }}>
            <div className="font-semibold text-[16px]">Genauigkeit</div>
            <div className="text-[12px]">
              ...<br />
              Platzhalter<br />
              ...
            </div>
          </Card>

          <div className="_content-overlay _mt-6">
            <div className="font-semibold text-[16px]">Meine Rezepte</div>
            <Collection className="details w-full" type="list" direction="column"
              items={recipesFiltered}>
              {(item, index) => {
                return <RecipeItem className="!w-full !border-none" key={item.id} org={org} item={item} hideFavStar={true} hasViewButton={false} />
              }}
            </Collection>
          </div>
        </Flex>
      </div>
    </div>

    {/* <small>* Die Icons "xmark (light)", "pen-to-square (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.</small> */}
  </>
}
