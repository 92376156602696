import { useContext, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Button, Card, Collection, Flex } from "@aws-amplify/ui-react"
import { FaPenToSquare, FaRegStar, FaXmark } from "react-icons/fa6"

import { FeedComponentDto } from "sds"

import { CURRENCY_SYMBOL, UNIT_OF_MASS } from "../../../org-settings"
import { AppContext, AppContextHelper } from "../../../AppContext"
import { getLinkToOrgFeedComponent } from "../../../LinkUtils"

import RecipeItem from "../-components/RecipeItem"
import { ensureImagePath } from "../-components/FeedComponentUtils"

import '../RecipeManagement.css'

export default function FeedComponent() {
  const params = useParams()
  const [bgImageSrc, setBgImageSrc] = useState<string>("")

  const appCtx = useContext(AppContext)
  const org = AppContextHelper.findOrg(params.orgId!, appCtx)
  const feedComponent: FeedComponentDto = AppContextHelper.findFeedComponent(org, params.feedComponentId!)

  const recipesFiltered = AppContextHelper.findRecipes(org)
    .filter(r => {
      let recipeContainsFc = false
      r.RecipeComponents.forEach(rfc => {
        if (rfc.feed_component_id === feedComponent.id) {
          recipeContainsFc = true
          return
        }
      })
      return recipeContainsFc
    })

  ensureImagePath(feedComponent, bgImageSrc, setBgImageSrc)

  return <>
    <div className="rm-view-page">
      <div className="hdr-img" style={{
        background: `transparent url('${bgImageSrc}') 0% 0% no-repeat padding-box`
      }} />
      <Link to='..'>
        <Button className="button-close has-icon"><span role="img" aria-label="Schließen"><FaXmark /></span></Button>
      </Link>
      <div className="content-overlay">
        <div className="header">
          <div className="info">
            <h2>{feedComponent.name}</h2>
            <div className="basic-info">
              <div>
                TS-Gehalt: {feedComponent.ts_gehalt_percent
                  ? `${feedComponent.ts_gehalt_percent.toLocaleString(undefined, { maximumFractionDigits: 1 })}%`
                  : 'nicht angegeben'}
              </div>
              <div>
                Preis: {feedComponent.price_per_kg
                  ? `${feedComponent.price_per_kg} ${CURRENCY_SYMBOL} / ${UNIT_OF_MASS}`
                  : 'nicht angegeben'}
              </div>
              {/* <div>
                Währung: {feedComponent.currency || "n/a"}
              </div>
              <div>
                Gruppe: {feedComponent.group || "n/a"}
              </div> */}
            </div>
          </div>
          <Link to={getLinkToOrgFeedComponent(org, feedComponent.id, true)}>
            <Button className="button-edit has-icon"><span role="img" aria-label="Bearbeiten"><FaPenToSquare /></span></Button>
          </Link>
        </div>

        <Flex direction={"row"}>
          <div className="_content-overlay _mt-6" style={{ width: "33%" }}>
            <div className="font-semibold text-[16px]">Meine Rezepte</div>
            <Collection className="details w-full" type="list" direction="column"
              items={recipesFiltered}>
              {(item, index) => {
                return <RecipeItem className="!w-full !border-none" key={item.id} org={org} item={item} hideFavStar={true} hasViewButton={false} />
              }}
            </Collection>
          </div>

          <Card className="!p-2" style={{ width: "33%" }}>
            <div className="font-semibold text-[16px]">Verbrauch</div>
            <div className="text-[12px]">
              ...<br />
              Platzhalter<br />
              ...
            </div>
          </Card>

          <Card className="!p-2" style={{ width: "33%" }}>
            <div className="font-semibold text-[16px]">Genauigkeit</div>
            <div className="text-[12px]">
              ...<br />
              Platzhalter<br />
              ...
            </div>
          </Card>
        </Flex>
      </div>
    </div>

    {/* <small>* Die Icons "xmark (light)", "pen-to-square (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.</small> */}
  </>
}
